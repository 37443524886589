.signup-page {
  color: yellow;
}
.signup-page .card-body {
  margin: 0 auto;
}
.google-btn {
  margin: 40px 0 0;
}
.signup-page a {
  color: #0f2f5f !important;
}

.signup-page .btn-success {
  background-color: #1d5ebe;
  color: #fff;
  border: 1px solid #1d5ebe;
  border-radius: 26px;
  height: 45px;
  width: 128px;
  white-space: nowrap;
  padding: 0 !important;
  position: relative;
  /* padding-left: 50px !important; */
  text-align: center;
}
.signup-page .btn-success:hover {
  border: 1px solid #0f2f5f;
  background-color: #0f2f5f;
  color: #fff;
  /* padding-left: 20px !important; */
}
.signup-page .btn-success .user-logo-img {
  margin-right: 12px;
  height: 16px;
  width: 16px;
  position: absolute;
  left: 25px;
  transition: ease all 1s;
}
.signup-page .btn-success:hover .user-logo-img {
  left: 80px;
}
.signup-page .btn-success .slide-text {
  margin-left: 30px;
  transition: ease all 1s;
}
.signup-page .btn-success:hover .slide-text {
  margin-left: -30px;
  transition: ease all 1s;
}

.signup-page .enter-form {
  margin: 40px 0 40px;
}
.signup-page label {
  color: #0f2f5f;
  font-size: 16px;
  line-height: 21px;
  font-weight: 600;
  margin: 0 0 15px;
}
.signup-page input {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #c6cfd9;
  border-radius: 8px;
  height: 52px;
  color: #000;
  width: 100% !important;
}
.signup-page input::placeholder {
  color: #7e92a8;
}
.signup-page .form-check-input[type="checkbox"] {
  width: auto !important;
  height: auto !important;
}
.signup-page .small-input input {
  width: 100% !important;
}
.signup-page .form-switch .form-check-input {
  width: 2em !important;
  height: 1em !important;
}

/* responsive */
@media only screen and (max-width: 1399px) {
}
@media only screen and (max-width: 1199px) {
  .no-show {
    display: none;
  }
}
@media only screen and (max-width: 991px) {
}
@media only screen and (max-width: 767px) {
  .full-width {
    width: 100%;
  }
  .signup-page input {
    width: 100% !important;
  }
}
@media only screen and (max-width: 575px) {
}
@media only screen and (max-width: 479px) {
}
@media only screen and (max-width: 319px) {
}
