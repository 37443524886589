.width-input {
  width: 100%;
}
.centerAlign {
  display: flex;
  justify-content: center;
}
/* .login-text{font-family: "Lucida Console", "Courier New", monospace;} */
.effect-16 {
  border: 0;
  padding: 4px 0;
  border-bottom: 1px solid #ccc;
  background-color: transparent;
  width: 100%;
}
.effect-16 ~ .focus-border {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #3399ff;
  transition: 0.4s;
}
.effect-16:focus ~ .focus-border,
.has-content.effect-16 ~ .focus-border {
  width: 100%;
  transition: 0.4s;
}
.effect-16 ~ label {
  position: absolute;
  left: 0;
  width: 100%;
  top: 9px;
  color: #aaa;
  transition: 0.3s;
  z-index: -1;
  letter-spacing: 0.5px;
}
.effect-16:focus ~ label,
.has-content.effect-16 ~ label {
  top: -16px;
  font-size: 12px;
  color: #3399ff;
  transition: 0.3s;
}
/**/
.login-page .card-body {
  margin: 0 auto;
}
.google-btn {
  margin: 40px 0 0;
}
.login-page a {
  color: #0f2f5f !important;
}
.login-page .btn-success {
  background-color: #1d5ebe;
  color: #fff;
  border: 1px solid #1d5ebe;
  border-radius: 26px;
  height: 45px;
  width: 128px;
  white-space: nowrap;
  padding: 0 !important;
  position: relative;
  /* padding-left: 50px !important; */
  text-align: center;
}
.login-page .btn-success:hover {
  border: 1px solid #0f2f5f;
  background-color: #0f2f5f;
  color: #fff;
  /* padding-left: 20px !important; */
}
.login-page .btn-success .user-logo-img {
  margin-right: 12px;
  height: 16px;
  width: 16px;
  position: absolute;
  left: 25px;
  transition: ease all 1s;
}
.login-page .btn-success:hover .user-logo-img {
  left: 80px;
}
.login-page .btn-success .slide-text {
  margin-left: 30px;
  transition: ease all 1s;
}
.login-page .btn-success:hover .slide-text {
  margin-left: -30px;
  transition: ease all 1s;
}

.login-page .enter-form {
  margin: 40px 0 40px;
}
.login-page label {
  color: #0f2f5f;
  font-size: 16px;
  line-height: 21px;
  font-weight: 600;
  margin: 0 0 15px;
}
.login-page input {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #c6cfd9;
  border-radius: 8px;
  height: 52px;
  color: #000;
  width: 100% !important;
}
.login-page input::placeholder {
  color: #7e92a8;
}
.login-main-img {
  width: 100%;
  height: 100vh;
}
.login-main-img img {
  width: 100%;
  height: 100%;
  /* filter: sepia(100%) hue-rotate(195deg) saturate(500%); */
}
.login-data {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.login-data::-webkit-scrollbar {
  background-color: #fff;
  width: 8px;
}
.login-data::-webkit-scrollbar-button {
  background-color: #313132;
}
.login-data::-webkit-scrollbar-track {
  background-color: #fff;
}
.login-data::-webkit-scrollbar-track-piece {
  background-color: #313132;
}
.login-data::-webkit-scrollbar-thumb {
  background: #0f3264 !important;
}
.login-data::-webkit-scrollbar-corner {
  background-color: #fff;
}
.login-data::-webkit-resizer {
  background-color: #fff;
}
.login-data {
  scrollbar-color: #0f3264 #000;
  scrollbar-width: thin;
}

/* responsive */
@media only screen and (max-width: 1399px) {
  .login-text {
    font-size: 30px !important;
  }
  .google-btn {
    margin: 25px 0 0;
  }
  .login-page .enter-form {
    margin: 40px 0 25px;
  }
  /* .cardWidth .card-body {max-width: 500px !important;} */
  /* .login-page input {
    width: 500px !important;
  } */
  /* .login-page .btn-primary {
    padding: 13px 50px;
  } */
}
@media only screen and (max-width: 1199px) {
  .no-show {
    display: none;
  }
}
@media only screen and (max-width: 991px) {
}
@media only screen and (max-width: 767px) {
}
@media only screen and (max-width: 575px) {
  .login-page input {
    width: 100% !important;
  }
  .cardWidth .card-body {
    padding: 50px 12px;
  }
}
@media only screen and (max-width: 479px) {
  .sign-in-area {
    flex-direction: column;
  }
}
@media only screen and (max-width: 319px) {
}
